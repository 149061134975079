import RestService from '../services/RestService';
import Constants from '../constants';

export const copyTemplate = (template, data) =>
  RestService.request('POST', `projects/copy?template=${template}`, data);

export const deleteProject = (projectId) =>
  RestService.request('DELETE', `projects/${projectId}`);

// Used when window is closed or reloaded
export const deleteProjectWithBeacon = (projectId) =>
  navigator.sendBeacon(
    `${process.env.REACT_APP_BACK_URL}delete_project/${projectId}`
  );

export const getCost = (projectId) =>
  RestService.request('GET', `projects/cost/${projectId}`);

export const updateProduct = (productId, data) =>
  RestService.request('PUT', `productDefinitions/${productId}`, data);

export const updateMultipleProducts = (data) =>
  RestService.request('PUT', `productDefinition/updateMultiple`, data);

export const updateSide = (productId, data) =>
  RestService.request('PUT', `sides/${productId}`, data);

export const updateMultipleSides = (data) =>
  RestService.request('PUT', `side/updateMultiple`, data);

export const getPublicIp = () => RestService.request('GET', 'public_ip');

export const getGlassChoices = (sideId) =>
  RestService.request(
    'GET',
    `productDefinitions/side/${sideId}/choices/${Constants.SAUNA_DOOR_TYPE}/parameters.glass`
  );
