const HandleColors1 = {
  colors: [
    { code: 'RAL9002', name: 'RAL9002 (Grey white)', hex: 'E7EBDA' },
    { code: 'RAL9004', name: 'RAL9004 (Signal black)', hex: '282828' },
    { code: "RAL7035", name: "RAL7035 (Light grey)", hex: "D7D7D7" }
  ],
};

export const HandleColors2 = {
  colors: [
    { code: 'RAL9002', name: 'RAL9002 (Grey white)', hex: 'E7EBDA' },
    { code: 'RAL9004', name: 'RAL9004 (Signal black)', hex: '282828' }
  ],
};

export default HandleColors1;
