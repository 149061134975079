/* eslint-disable react-hooks/exhaustive-deps */
import './colors_box.css';
import Select from 'react-select';
import chroma from 'chroma-js';
import React, { useEffect, useMemo, useState } from 'react';
import { mapColorToIndex, scrollToBottom } from '../../utils';

const color = chroma('black');
const colorSelected = color.alpha(0.3).css();
const colorFocused = color.alpha(0.2).css();
const colorActive = color.alpha(0.3).css();

const customStyles = {
  option: (provided, { isDisabled, isFocused, isSelected }) => ({
    ...provided,
    background: isDisabled
      ? null
      : isSelected
      ? colorSelected
      : isFocused
      ? colorFocused
      : null,
    cursor: 'pointer',
    ':active': {
      ...provided[':active'],
      background: !isDisabled && (isSelected ? color : colorActive),
    },
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: '0',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({}),
};

const ColorsBox = ({ sendRequest, color, colorOptions, t, isAtTheEnd }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const colorIndex = useMemo(() => mapColorToIndex(color, colorOptions), [
    color,
  ]);

  useEffect(() => {
    if (isAtTheEnd && menuOpened) {
      scrollToBottom();
    }
  }, [menuOpened, isAtTheEnd]);

  const colorsList = useMemo(
    () =>
      colorOptions.colors.map((color) => ({
        value: color.name,
        label: (
          <div className="color-container">
            <div
              className="color-box"
              style={{
                background: `#${color.hex}`,
                verticalAlign: 'middle',
              }}
            />
            <div className="color-name">{t(`colors.${color.name}`)}</div>
          </div>
        ),
      })),
    [colorOptions]
  );

  return (
    <div className="select-colors-box--container">
      <Select
        options={colorsList}
        value={colorsList[colorIndex]}
        styles={customStyles}
        isSearchable={false}
        onChange={(color) => {
          sendRequest(color.value);
        }}
        placeholder="Valitse..."
        onMenuOpen={() => setMenuOpened(true)}
        onMenuClose={() => setMenuOpened(false)}
      />
    </div>
  );
};

ColorsBox.defaultProps = {
  isAtTheEnd: false,
};

export default ColorsBox;
