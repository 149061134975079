import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useForm from '../providers/FormProvider/use';

const withForm = (InputComponent) => {
  const WrappedWithForm = ({
    name,
    validator,
    initialValue,
    onChange,
    ...props
  }) => {
    const {
      errors,
      data,
      setFieldValue,
      changeValidator,
      registerInput,
      unregisterInput,
      handleOnBlur,
    } = useForm();

    useEffect(() => {
      registerInput(name, initialValue, validator);

      return function cleanup() {
        unregisterInput(name);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      changeValidator(name, validator);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validator]);

    const handleOnChange = (val) => {
      setFieldValue(name, val);
      if (onChange) onChange(name, val, data);
    };

    const inputValue = data[name];
    const inputErrors = errors[name];

    return (
      <InputComponent
        name={name}
        value={inputValue}
        errors={inputErrors}
        handleOnChange={handleOnChange}
        handleOnBlur={handleOnBlur}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  };

  WrappedWithForm.propTypes = {
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/require-default-props
    validator: PropTypes.func,
  };

  return WrappedWithForm;
};

export default withForm;
