const Constants = {
  MAIN_COLOR: '#33426C',
  SAUNA_DOOR_TYPE: 'sauna_door',
  REFERENCE_BAR_TYPE: 'reference_bar',
  GLASS: 0,
  DOOR: 1,
  WALL: 2,
  HANDLE_LEFT: 0,
  HANDLE_RIGHT: 1,
  LOADING_TYPE: 'spokes',
  DEFAULT_PROJECT_TEMPLATE: 1,
  DEFAULT_SECTION: 1
};
export default Constants;
