/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../components/NumberInput';
import {
  MIN_DOOR_HEIGHT,
  MIN_UPPER_GLASS_HEIGHT,
  MAX_DOOR_HEIGHT,
  MIN_WALL_HEIGHT,
  MAX_WALL_HEIGHT,
} from '../../constants/validations';

const HeightField = ({ name, label, initialValue }) => {
  const { t } = useTranslation('common');
  const validateDoorHeight = (newValue, data) => {
    if (newValue < MIN_DOOR_HEIGHT) {
      return [t('warnings.less_than', { number: MIN_DOOR_HEIGHT })];
    }
    const maxHeight = parseInt(data.system_height) - MIN_UPPER_GLASS_HEIGHT;
    const myMaxHeight = Math.min(maxHeight, MAX_DOOR_HEIGHT);
    if (newValue > myMaxHeight) {
      return [t('warnings.more_than', { number: myMaxHeight, add: 'Tarkista huonekorkeus' })];
    }
    return [];
  };

  const validateWallHeight = (newValue) => {
    if (newValue < MIN_WALL_HEIGHT) {
      return [t('warnings.less_than', { number: MIN_WALL_HEIGHT })];
    }
    if (newValue > MAX_WALL_HEIGHT) {
      return [t('warnings.more_than', { number: MAX_WALL_HEIGHT })];
    }

    return [];
  };

  const validate = useCallback((val, data) => {
    if (name === 'door_height') {
      return validateDoorHeight(val, data);
    }

    return validateWallHeight(val);
  }, []);

  return (
    <NumberInput
      name={name}
      label={label}
      initialValue={initialValue}
      validator={validate}
    />
  );
};

export default HeightField;
