/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import './parameters.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import ColorsBox from '../../components/SelectBox/ColorsBox';
import GlassOptions from '../../components/SelectBox/GlassOptions';
import TextBox from '../../components/SelectBox/TextBox';
import SystemWidth from './SystemWidth';
import SystemHeight from './SystemHeight';
import WidthField from './WidthField';
import HeightField from './HeightField';
import {
  productDefinitionQueryBody,
  productDefinitionQueryBodyMultiple,
} from '../../utils';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import Constants from '../../constants/index';
import HandleColors, { HandleColors2 } from '../../data/HandleColors';
import Modal from '../../components/Modal';
import useOptions from '../../hooks/useOptions';
import useProjectData from '../../providers/ProjectDataProvider/use';

const DEFAULT_BOTTOM_GAP = 8;
const FLOOR_CLAMP_BOTTOM_GAP = 110;

const Parameters = ({ goBack, makeUpdateRequest }) => {
  const { t } = useTranslation('common');
  
  const { projectData, dispatch } = useProjectData();
  const [
    handleSideOptions,
    glassFixingOptions,
    handleTypeOptions,
  ] = useOptions();

  const isTemplateLoading = projectData.projectId === null;

  const glassFixingValue =
    projectData.leftSide && projectData.leftSide.bottomEdgeProfile !== undefined
      ? projectData.leftSide.bottomEdgeProfile
      : projectData.rightSide &&
        projectData.rightSide.bottomEdgeProfile !== undefined
      ? projectData.rightSide.bottomEdgeProfile
      : null;

  const showGlassFixing =
    !isTemplateLoading &&
    ((projectData.leftSide &&
      projectData.leftSide.referenceBarId === undefined) ||
      (projectData.rightSide &&
        projectData.rightSide.referenceBarId === undefined));

  const glassColorQuery = (value) => {
    const parameters = [];
    const ids = [];
    parameters.push({
      glass: value,
    });

    parameters.push({
      glass: value,
    });

    ids.push(projectData.door.glassId);
    ids.push(projectData.door.doorId);

    if (projectData.leftSide) {
      parameters.push({
        glass: value,
      });

      ids.push(projectData.leftSide.glassId);
    }

    if (projectData.rightSide) {
      parameters.push({
        glass: value,
      });

      ids.push(projectData.rightSide.glassId);
    }

    const data = productDefinitionQueryBodyMultiple(
      Constants.SAUNA_DOOR_TYPE,
      parameters,
      ids
    );

    makeUpdateRequest(data, 'productDefinition/updateMultiple');
    const newDoorsData = { ...projectData.door, glassValue: value };
    dispatch({ type: 'door', payload: newDoorsData });
  };

  const handleColorQuery = (color) => {
    const parameters = [];
    const ids = [];

    parameters.push({
      AccessoriesColor: color,
    });

    parameters.push({
      AccessoriesColor: color,
    });

    ids.push(projectData.door.glassId);
    ids.push(projectData.door.doorId);

    if (projectData.leftSide) {
      parameters.push({
        AccessoriesColor: color,
      });

      ids.push(projectData.leftSide.glassId);
    }

    if (projectData.rightSide) {
      parameters.push({
        AccessoriesColor: color,
      });

      ids.push(projectData.rightSide.glassId);
    }

    const data = productDefinitionQueryBodyMultiple(
      Constants.SAUNA_DOOR_TYPE,
      parameters,
      ids
    );
    makeUpdateRequest(data, 'productDefinition/updateMultiple');
    const newDoorsData = { ...projectData.door, AccessoriesColor: color };
    dispatch({ type: 'door', payload: newDoorsData });
  };

  const handleSideQuery = (side) => {
    const data = productDefinitionQueryBody(Constants.SAUNA_DOOR_TYPE, {
      handleLocation: side,
    });
    makeUpdateRequest(data, 'productDefinitions', projectData.door.doorId);
    const newDoorData = { ...projectData.door, handleLocation: side };
    dispatch({ type: 'door', payload: newDoorData });
  };

  const handleTypeQuery = (type) => {
    const data = productDefinitionQueryBody(Constants.SAUNA_DOOR_TYPE, {
      handleType: type,
    });
    makeUpdateRequest(data, 'productDefinitions', projectData.door.doorId);
    const newDoorData = { ...projectData.door, handleType: type };
    dispatch({ type: 'door', payload: newDoorData });
  };

  const glassFixingQuery = (value) => {
    const newBottomSpace =
      value === 2 ? FLOOR_CLAMP_BOTTOM_GAP : DEFAULT_BOTTOM_GAP;

    const parameters = [];
    const ids = [];

    let newLeftSideData = {};
    let newRightSideData = {};

    if (
      projectData.leftSide &&
      projectData.leftSide.referenceBarId === undefined
    ) {
      parameters.push({
        bottomEdgeProfile: value,
        bottomSpace: newBottomSpace,
      });
      ids.push(projectData.leftSide.glassId);
      newLeftSideData = {
        left: {
          ...projectData.leftSide,
          bottomEdgeProfile: value,
        },
      };

      dispatch({ type: 'left_side', payload: newLeftSideData.left });
    }
    if (
      projectData.rightSide &&
      projectData.rightSide.referenceBarId === undefined
    ) {
      parameters.push({
        bottomEdgeProfile: value,
        bottomSpace: newBottomSpace,
      });
      ids.push(projectData.rightSide.glassId);
      newRightSideData = {
        right: {
          ...projectData.rightSide,
          bottomEdgeProfile: value,
        },
      };

      dispatch({ type: 'right_side', payload: newRightSideData.right });
    }

    const data = productDefinitionQueryBodyMultiple(
      Constants.SAUNA_DOOR_TYPE,
      parameters,
      ids
    );

    makeUpdateRequest(data, 'productDefinition/updateMultiple');
  };

  return (
    <>
      <div id="parameters-container">
        <h1 className="title">{t('page_2.glass_color')}</h1>
        {isTemplateLoading && <LoadingPlaceholder />}
        {!isTemplateLoading && (
          <>
            <GlassOptions
              sendRequest={glassColorQuery}
              options={projectData.glassOptions}
              value={projectData.door.glassValue}
              t={t}
            />
            <div className="parameters-horizontal-line" />
          </>
        )}
        <div className="opening-dimensions">
          <h1 className="title">{t('page_2.opening_dimensions')}</h1>
          <Modal>
            <img
              alt="info_layout"
              src={`/images/layout_${projectData.currentTemplate}.jpg`}
              className="responsive-image"
            />
          </Modal>
        </div>
        {isTemplateLoading && <LoadingPlaceholder />}
        {!isTemplateLoading && (
          <>
            {projectData.systemHeight && (
              <SystemHeight initialValue={projectData.systemHeight} />
            )}

            {projectData.systemWidth && (
              <SystemWidth initialValue={projectData.systemWidth} />
            )}

            {projectData.door && (
              <>
                {projectData.door.width && (
                  <WidthField
                    name="door_width"
                    label={t('page_2.door_width')}
                    initialValue={projectData.door.width}
                    isDisabled={false}
                  />
                )}

                {projectData.door.beforeSpace && (
                  <HeightField
                    name="door_height"
                    label={t('page_2.door_height')}
                    initialValue={projectData.door.beforeSpace}
                  />
                )}
              </>
            )}
            {projectData.leftSide && (
              <>
                {projectData.leftSide.width && (
                  <WidthField
                    name="left_glass_width"
                    label={t('page_2.left_glass_width')}
                    initialValue={projectData.leftSide.width}
                    isDisabled={projectData.rightSide === undefined}
                  />
                )}

                {projectData.leftSide.referenceBarId &&
                  projectData.leftSide.beforeSpace && (
                    <HeightField
                      name="left_wall_height"
                      label={t('page_2.left_glass_height')}
                      initialValue={projectData.leftSide.beforeSpace}
                    />
                  )}
              </>
            )}
            {projectData.rightSide && (
              <>
                {projectData.rightSide.width && (
                  <WidthField
                    name="right_glass_width"
                    label={t('page_2.right_glass_width')}
                    initialValue={projectData.rightSide.width}
                    isDisabled={projectData.leftSide === undefined}
                  />
                )}
                {projectData.rightSide.referenceBarId &&
                  projectData.rightSide.beforeSpace && (
                    <HeightField
                      name="right_wall_height"
                      label={t('page_2.right_glass_height')}
                      initialValue={projectData.rightSide.beforeSpace}
                    />
                  )}
              </>
            )}
            <div className="parameters-horizontal-line" />
          </>
        )}

        <h1 className="title">{t('page_2.handle_type')}</h1>
        {isTemplateLoading && <LoadingPlaceholder />}
        {!isTemplateLoading && (
          <>
            <TextBox
              options={handleTypeOptions}
              sendRequest={handleTypeQuery}
              selectedOption={projectData.door.handleType}
            />
            <div className="parameters-horizontal-line" />
          </>
        )}

        <h1 className="title">{t('page_2.handle_side')}</h1>
        {isTemplateLoading && <LoadingPlaceholder />}
        {!isTemplateLoading && (
          <>
            <TextBox
              options={handleSideOptions}
              sendRequest={handleSideQuery}
              selectedOption={projectData.door.handleLocation}
              key="handle-side"
            />
            <div className="parameters-horizontal-line" />
          </>
        )}
        <div className="opening-dimensions">
          <h1 className="title">{t('page_2.handle_color')}</h1>
          <Modal>
            <div className="modal-c-root">
              <h4>Järjestelmän väri valinta vaikuttaa seuraaviin asioihin</h4>
              <ul className="modal-content">
                <li>{t('page_2.handle_color_param1')}</li>
                <li>{t('page_2.handle_color_param2')}</li>
                <li>{t('page_2.handle_color_param3')}</li>
                <li>{t('page_2.handle_color_param4')}</li>
              </ul>
            </div>
          </Modal>
        </div>
        {isTemplateLoading && <LoadingPlaceholder />}
        {!isTemplateLoading && (
          <>
            <ColorsBox
              sendRequest={handleColorQuery}
              color={projectData.door.AccessoriesColor}
              colorOptions={
                projectData.door.handleType === 2 ? HandleColors2 : HandleColors
              }
              t={t}
              isAtTheEnd
            />
            <div className="parameters-horizontal-line" />
          </>
        )}
        {showGlassFixing && (
          <>
            <h1 className="title">{t('page_2.glass_bottom_fixing')}</h1>
            {isTemplateLoading && <LoadingPlaceholder />}
            {!isTemplateLoading && (
              <>
                <TextBox
                  options={glassFixingOptions}
                  sendRequest={glassFixingQuery}
                  selectedOption={glassFixingValue}
                  isAtTheEnd
                />
                <div className="parameters-horizontal-line" />
              </>
            )}
          </>
        )}
      </div>
      <div
        onClick={goBack}
        className="button color1 text-center next-button desktop-button"
        role="button"
        tabIndex={0}
      >
        <div className="button-triangle--back--desktop" />
        <div>{t('back_button')}</div>
      </div>
    </>
  );
};

export default Parameters;
