import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useOptions = () => {
  const { t } = useTranslation('common');

  const handleSideOptions = useMemo(
    () => [
      {
        value: 0,
        label: <div>{t('page_2.handle_side_options.left')}</div>,
      },
      {
        value: 1,
        label: <div>{t('page_2.handle_side_options.right')}</div>,
      },
    ],
    [t]
  );

  const glassFixingOptions = useMemo(
    () => [
      {
        value: 1,
        label: <div>{t('page_2.glass_fixing_options.profile')}</div>,
      },
      {
        value: 2,
        label: <div>{t('page_2.glass_fixing_options.floor_clamp')}</div>,
      },
    ],
    [t]
  );

  const handleTypeOptions = useMemo(
    () => [
      {
        value: 1,
        label: <div>Nuppi</div>,
      },
      {
        value: 2,
        label: <div>Vedin</div>,
      },
    ],
    []
  );

  return [handleSideOptions, glassFixingOptions, handleTypeOptions];
};

export default useOptions;
