/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../components/NumberInput';
import useProjectData from '../../providers/ProjectDataProvider/use';
import useForm from '../../providers/FormProvider/use';
import {
  MIN_DOOR_WIDTH,
  MAX_DOOR_WIDTH,
  MIN_GLASS_WIDTH,
  MAX_GLASS_WDITH,
} from '../../constants/validations';
import ProjectService from '../../services/ProjectService';

const WidthField = ({ name, label, initialValue, isDisabled }) => {
  const { projectData } = useProjectData();
  const { setFieldValue } = useForm();
  const { t } = useTranslation('common');

  const validateDoorWidth = (newValue) => {
    if (newValue < MIN_DOOR_WIDTH) {
      return [t('warnings.less_than', { number: MIN_DOOR_WIDTH })];
    }
    if (newValue > MAX_DOOR_WIDTH) {
      return [t('warnings.more_than', { number: MAX_DOOR_WIDTH })];
    }

    return [];
  };

  const validateGlassWidth = (newValue) => {
    if (newValue < MIN_GLASS_WIDTH) {
      return [t('warnings.less_than', { number: MIN_GLASS_WIDTH })];
    }
    if (newValue > MAX_GLASS_WDITH) {
      return [t('warnings.more_than', { number: MAX_GLASS_WDITH })];
    }

    return [];
  };

  const validate = useCallback((val) => {
    if (name === 'door_width') {
      return validateDoorWidth(val);
    }

    return validateGlassWidth(val);
  }, []);

  const handleOnChange = (name, value) => {
    let systemWidth = projectData.door.width;
    const currentValues = { door: projectData.door.width };
    
    if (projectData.leftSide !== undefined) {
      currentValues.left = projectData.leftSide.width;
      systemWidth += projectData.leftSide.width;
    }

    if (projectData.rightSide !== undefined) {
      currentValues.right = projectData.rightSide.width;
      systemWidth += projectData.rightSide.width;
    }

    currentValues.system = systemWidth;

    const sidesWidth = ProjectService.getSidesWidth(currentValues, name, value);

    for (const [type, value] of Object.entries(sidesWidth)) {
      setFieldValue(type, value);
    }
  };

  return (
    <NumberInput
      name={name}
      label={label}
      isDisabled={isDisabled}
      initialValue={initialValue}
      validator={validate}
      onChange={handleOnChange}
    />
  );
};

export default WidthField;
