/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../components/NumberInput';
import {
  MIN_UPPER_GLASS_HEIGHT,
  MIN_SYSTEM_HEIGHT,
  MAX_SYSTEM_HEIGHT,
} from '../../constants/validations';

const SystemHeight = ({ initialValue }) => {
  const { t } = useTranslation('common');

  const validate = useCallback((newValue, data) => {
    const minHeight = Math.max(
      data.door_height + MIN_UPPER_GLASS_HEIGHT,
      MIN_SYSTEM_HEIGHT
    );

    if (newValue < minHeight) {
      return [t('warnings.less_than',{ number: minHeight, add: 'Tarkista oven korkeus'})];
    }

    if (newValue > MAX_SYSTEM_HEIGHT) {
      return [t('warnings.more_than', { number: MAX_SYSTEM_HEIGHT })];
    }

    return [];
  }, []);

  return (
    <NumberInput
      name="system_height"
      label={t('page_2.height')}
      validator={validate}
      initialValue={initialValue}
    />
  );
};

export default SystemHeight;
