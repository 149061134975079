const reducer = (state, action) => {
  switch (action.type) {
    case 'project_name':
      return { ...state, projectName: action.payload };
    case 'share_id':
      return { ...state, shareId: action.payload };
    case 'project_id':
      return { ...state, projectId: action.payload };
    case 'drawing_id':
      return { ...state, drawingId: action.payload };
    case 'system_height':
      return { ...state, systemHeight: action.payload };
    case 'system_width':
      return { ...state, systemWidth: action.payload };
    case 'loading_cost':
      return { ...state, isLoadingCost: action.payload };
    case 'loading_model':
      return { ...state, isLoadingModel: action.payload };
    case 'project_cost':
      return { ...state, projectCost: action.payload };
    case 'left_side':
      return { ...state, leftSide: action.payload };
    case 'right_side':
      return { ...state, rightSide: action.payload };
    case 'door':
      return { ...state, door: action.payload };
    case 'current_section':
      return { ...state, currentSection: action.payload };
    case 'current_template':
      return { ...state, currentTemplate: action.payload };
    case 'glass_options':
      return { ...state, glassOptions: action.payload };
    case 'clear_project':
      return {
        ...state,
        leftSide: null,
        door: null,
        rightSide: null,
        projectId: null,
        drawingId: null,
        systemHeight: null,
        systemWidth: null,
        isLoadingModel: true,
        isLoadingCost: true,
      };
    case 'sides':
      return {
        ...state,
        leftSide: action.payload.leftSide,
        rightSide: action.payload.rightSide,
        door: action.payload.door,
      };
    case 'drawing':
      return {
        ...state,
        drawingId: action.payload.drawingId,
        systemWidth: action.payload.systemWidth,
        systemHeight: action.payload.systemHeight,
      };
    default:
      throw new Error(`Not a valid action: ${action.type}`);
  }
};

export default reducer;
