const initialState = {
  projectName: null,
  shareId: null,
  projectId: null,
  drawingId: null,
  systemHeight: null,
  systemWidth: null,
  isLoadingCost: true,
  isLoadingModel: true,
  projectCost: null,
  leftSide: null,
  door: null,
  rightSide: null,
  glazingHeight: null,
  currentSection: 1,
  currentTemplate: 1,
  glassOptions: null,
};

export default initialState;
