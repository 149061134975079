const Templates = [
  {
    name: 'Template 1',
    image: './images/template1.svg',
  },
  {
    name: 'Template 2',
    image: './images/template2.svg',
  },
  {
    name: 'Template 3',
    image: './images/template3.svg',
  },
  {
    name: 'Template 4',
    image: './images/template4.svg',
  },
  {
    name: 'Template 5',
    image: './images/template5.svg',
  },
  {
    name: 'Template 6',
    image: './images/template6.svg',
  },
  {
    name: 'Template 7',
    image: './images/template7.svg',
  },
  {
    name: 'Template 8',
    image: './images/template8.svg',
  },
];

export default Templates;
