/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import Modal from 'react-modal';
import InformationIcon from '../InformationIcon';
import './modal.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 20,
  },
};

Modal.setAppElement('#root');

const MyModal = ({ children }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <button type="button" onClick={openModal} className="open-modal-button">
        <InformationIcon />
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button
          className="close-button"
          type="button"
          onClick={closeModal}
          style={{ float: 'right' }}
        >
          .
        </button>
        {children}
      </Modal>
    </div>
  );
};

export default MyModal;
