/* eslint-disable class-methods-use-this */
import Constants from '../constants';

class ProjectService {
  getSystemWidth(sides) {
    let systemWidth = 0;
    sides.forEach((side) => {
      systemWidth += side.width;
    });

    return systemWidth;
  }

  getSideData(sideId, sideWidth, productDefinitions, edge) {
    const prodDefinitionsFromSide = productDefinitions.filter(
      (productDefinition) => productDefinition.side === sideId
    );

    const sideData = { id: sideId, width: sideWidth };
    let chosenEdge = edge;

    prodDefinitionsFromSide.forEach((productDefinition) => {
      const { id, parameters, type, spacing } = productDefinition;

      if (type === Constants.REFERENCE_BAR_TYPE) {
        sideData.referenceBarId = id;
        sideData.beforeSpace = spacing.beforeSpace;
      } else if (parameters.productType === Constants.GLASS) {
        sideData.glassId = id;
        sideData.bottomEdgeProfile = parameters.bottomEdgeProfile;
      } else if (parameters.productType === Constants.DOOR) {
        chosenEdge = 'door';
        sideData.doorId = id;
        sideData.AccessoriesColor = parameters.AccessoriesColor;
        sideData.handleLocation = parameters.handleLocation;
        sideData.handleType = parameters.handleType;
        sideData.glassValue = parameters.glass;
      }
    });

    return [chosenEdge, sideData];
  }

  getSidesInfo(sides, productDefinitions) {
    const sidesData = {};
    const edges = ['left', 'right'];

    if (sides.length === 3) edges.splice(1, 0, 'door');

    sides.forEach((side, index) => {
      const [chosenEdge, sideData] = this.getSideData(
        side.id,
        side.width,
        productDefinitions,
        edges[index]
      );
      sidesData[chosenEdge] = sideData;
    });

    return [sidesData.left, sidesData.right, sidesData.door];
  }

  getSidesWidth(currentValues, type, newValue) {
    const newValues = {};

    if (type === 'system') {
      if (
        currentValues.left !== undefined &&
        currentValues.right !== undefined
      ) {
        if (currentValues.right === 0) {
          newValues.right_glass_width = 0;
        } else {
          newValues.right_glass_width = Math.floor(
            (currentValues.right / (currentValues.left + currentValues.right)) *
              (newValue - currentValues.door)
          );
        }
        newValues.left_glass_width =
          newValue - currentValues.door - newValues.right_glass_width;
      } else if (currentValues.left !== undefined) {
        newValues.left_glass_width = newValue - currentValues.door;
      } else {
        newValues.right_glass_width = newValue - currentValues.door;
      }

      return newValues;
    }

    if (type === 'door_width') {
      if (
        currentValues.left !== undefined &&
        currentValues.right !== undefined
      ) {
        newValues.right_glass_width = Math.floor(
          (currentValues.right / (currentValues.left + currentValues.right)) *
            (currentValues.system - newValue)
        );
        newValues.left_glass_width =
          currentValues.system - newValue - newValues.right_glass_width;
      } else if (currentValues.left !== undefined) {
        newValues.left_glass_width = currentValues.system - newValue;
      } else {
        newValues.right_glass_width = currentValues.system - newValue;
      }

      return newValues;
    }

    if (type === 'left_glass_width' && currentValues.right !== undefined) {
      newValues.right_glass_width =
        currentValues.system - currentValues.door - newValue;
      return newValues;
    }

    if (type === 'right_glass_width' && currentValues.left !== undefined) {
      newValues.left_glass_width =
        currentValues.system - currentValues.door - newValue;
      return newValues;
    }

    return null;
  }
}

export default new ProjectService();
