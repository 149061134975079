/* eslint-disable react/no-array-index-key */
import './number_input.css';
import React, { useState } from 'react';
import withForm from '../../HOCs/withForm';

// sendRequest,
const NumberInput = ({
  name,
  label,
  value,
  errors,
  isDisabled,
  handleOnBlur,
  handleOnChange,
}) => {
  const [inputRef] = useState(React.createRef());
  const hasErrors = errors ? errors.length > 0 : false;

  const renderErrors = () => {
    if (!hasErrors) {
      return null;
    }

    // eslint-disable-next-line no-unused-vars
    const errorsResult = errors.map((errMsg) => (
      <div className="feedback-message">{errMsg}</div>
    ));
    return errorsResult;
  };

  const onChange = (e) => {
    const val = e.target.value;
    handleOnChange(parseInt(val));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  return (
    <>
      <div className={`group-input ${hasErrors ? 'invalid-group-input' : ''}`}>
        <label className="text-color1">{label}</label>
        <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
          <input
            className="custom-input"
            type="number"
            value={value}
            name={name}
            onChange={onChange}
            disabled={isDisabled}
            onKeyPress={handleKeyPress}
            onBlur={() => {
              if (handleOnBlur) handleOnBlur();
            }}
            ref={inputRef}
          />
          <div className="mm">mm</div>
        </div>
      </div>
      {renderErrors()}
    </>
  );
};

NumberInput.defaultProps = {
  isDisabled: false,
};

const NumberInputWithForm = withForm(NumberInput);

export default NumberInputWithForm;
