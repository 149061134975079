import React from 'react';
import ReactLoading from 'react-loading';
import Constants from '../constants';

const CieloModel = ({ sharedLinkCode, loading }) => (
  <>
    {loading && (
      <div
        style={{ width: 'fit-content', margin: '0 auto', marginTop: '300px' }}
      >
        <ReactLoading
          type={Constants.LOADING_TYPE}
          color={Constants.MAIN_COLOR}
        />
      </div>
    )}
    {!loading && (
      <iframe
        id="cielo-iframe"
        src={`${process.env.REACT_APP_IFRAME_URL}3d.php?s=${sharedLinkCode}&loc=0.00%2C0.00%2C5.41&look=0.00%2C0.00%2C0.00&header=0&controls=0&visualization_options=0&lang=fi`}
        title="Generated 3D Balcony Model"
      />
    )}
  </>
);

export default CieloModel;
