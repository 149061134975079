/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import FormContext from './context';

const initialFormState = {
  data: {},
  validators: {},
  errors: {},
};

const FormProvider = ({
  onSubmit,
  onReset,
  onBlur,
  id,
  className,
  liveValidation,
  children,
}) => {
  const [formState, setFormState] = useState(initialFormState);
  useEffect(() => {
    if (liveValidation) validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.data]);

  const submit = (e) => {
    e.preventDefault();
    if (validate() && onSubmit) onSubmit(formState.data);
  };

  const handleOnBlur = () => {
    if (onBlur) onBlur(formState.data, formState.errors);
  };

  const validate = () => {
    const { validators } = formState;

    if (isEmpty(validators)) return true;

    const formErrors = {};
    for (const [name, validator] of Object.entries(validators)) {
      const { data } = formState;
      const errorMessages = validator(data[name], data);
      formErrors[name] = errorMessages;
    }

    if (isEmpty(formErrors)) return true;

    setFormState((state) => ({
      ...state,
      errors: formErrors,
    }));

    return false;
  };

  const reset = (e) => {
    e.preventDefault();
    setFormState(initialFormState);
    if (onReset) {
      onReset();
    }
  };

  const setFieldValue = (name, value) => {
    setFormState((state) => ({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      }
    }));
  };

  const changeValidator = (name, newValidator) => {
    setFormState((state) => ({
      ...state,
      validators: {
        ...state.validators,
        [name]: newValidator,
      }
    }));
  };

  const registerInput = (name, initialValue, validator) => {
    setFormState((state) => {
      const validators = { ...state.validators };
      if (validator) validators[name] = validator;

      return {
        data: {
          ...state.data,
          [name]: initialValue,
        },
        validators,
        errors: {
          ...state.errors,
          [name]: [],
        },
      };
    });
  };

  const unregisterInput = (name) => {
    setFormState((state) => {
      // copy state to avoid mutating it
      const { data, errors, validators } = { ...state };

      // clear field data, validations and errors
      delete data[name];
      delete errors[name];
      delete validators[name];

      return {
        data,
        errors,
        validators,
      };
    });
  };

  return (
    <FormContext.Provider
      value={{
        errors: formState.errors,
        data: formState.data,
        setFieldValue,
        changeValidator,
        registerInput,
        unregisterInput,
        handleOnBlur,
      }}
    >
      <form onSubmit={submit} onReset={reset} className={className} id={id}>
        {children}
      </form>
    </FormContext.Provider>
  );
};

FormProvider.propTypes = {
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  liveValidation: PropTypes.bool,
};

export default FormProvider;
