/* eslint-disable react-hooks/exhaustive-deps */
import './colors_box.css';
import Select from 'react-select';
import chroma from 'chroma-js';
import React, { useMemo } from 'react';

const color = chroma('black');
const colorSelected = color.alpha(0.3).css();
const colorFocused = color.alpha(0.2).css();
const colorActive = color.alpha(0.3).css();

const customStyles = {
  option: (provided, { isDisabled, isFocused, isSelected }) => ({
    ...provided,
    background: isDisabled
      ? null
      : isSelected
        ? colorSelected
        : isFocused
          ? colorFocused
          : null,
    cursor: 'pointer',
    ':active': {
      ...provided[':active'],
      background: !isDisabled && (isSelected ? color : colorActive),
    },
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: '0',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({}),
};

const getGlassIndex = (options, value) => {
  const arrayLength = options.length;
  for (let i = 0; i < arrayLength; i += 1) {
    // eslint-disable-next-line eqeqeq
    if (parseInt(options[i].value, 10) === value) return i;
  }
  return null;
}

const GlassOptions = ({ sendRequest, options, value }) => {
  const glassIndex = getGlassIndex(options, value);
  const glassesList = useMemo(
    () =>
      options.map((option) => ({
        value: option.value,
        label: (
          <div className="color-container">
            <div
              className="color-box"
              style={{
                background: option.color ? `#${option.color.toString(16).substr(2)}` : 'black',
                verticalAlign: 'middle',
              }}
            />
            <div className="color-name">{option.label}</div>
          </div>
        ),
      })),
    []
  );

  return (
    <div className="select-colors-box--container">
      <Select
        options={glassesList}
        value={glassesList[glassIndex]}
        styles={customStyles}
        isSearchable={false}
        onChange={(element) => {
          sendRequest(parseInt(element.value, 10));
        }}
        placeholder="Valitse..."
      />
    </div>
  );
};

export default GlassOptions;
