/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react';
import { isEmpty } from 'lodash';
import ProjectDataContext from './context';
import initialState from './initialState';
import Templates from '../../data/Templates';
import {
  deleteProject,
  copyTemplate,
  getGlassChoices,
  getPublicIp,
} from '../../api';
import reducer from './reducer';
import ProjectService from '../../services/ProjectService';
import { getCurrentDate } from '../../utils';

let LAST_QUERY = 0;

const ProjectDataProvider = ({ children }) => {
  const [projectData, dispatch] = useReducer(reducer, initialState);
  const [copiedProject, setCopiedProject] = useState(null);

  const copyProject = (template, lastQuery) => {
    if (projectData.projectId !== null) {
      deleteProject(projectData.projectId);
    }

    dispatch({ type: 'clear_project' });
    copyTemplate(template, {
      newName: `${Templates[template - 1].name} / ${projectData.projectName}`,
      customerName: '',
    })
      .then((response) => {
        if (lastQuery === LAST_QUERY)
          setCopiedProject({ ...response, lastQuery });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setGlasses = () => {
    const { sides } = copiedProject.targetInfo.drawings[0];

    getGlassChoices(sides.result[0].id)
      .then((glasses) => {
        dispatch({ type: 'glass_options', payload: glasses });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleProjectData = () => {
    const newProjectId = copiedProject.project;
    const { shareId } = copiedProject.targetInfo;

    dispatch({ type: 'share_id', payload: shareId });
    dispatch({ type: 'project_id', payload: newProjectId });

    const {
      drawing,
      sides,
      productDefinitions,
    } = copiedProject.targetInfo.drawings[0];

    const systemWidth = ProjectService.getSystemWidth(sides.result);
    const systemHeight = sides.result[0].height;

    const [leftSide, rightSide, door] = ProjectService.getSidesInfo(
      sides.result,
      productDefinitions.result
    );

    dispatch({ type: 'loading_model', payload: false });
    dispatch({ type: 'loading_cost', payload: false });
    dispatch({ type: 'sides', payload: { leftSide, rightSide, door } });
    dispatch({
      type: 'drawing',
      payload: { drawingId: drawing.id, systemWidth, systemHeight },
    });
  };

  useEffect(() => {
    getPublicIp()
      .then((data) => {
        dispatch({
          type: 'project_name',
          payload: `${data.ip} / ${getCurrentDate()}`,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (projectData.projectName === null) return;
    LAST_QUERY += 1;
    copyProject(projectData.currentTemplate, LAST_QUERY);
  }, [projectData.currentTemplate, projectData.projectName]);

  useEffect(() => {
    if (copiedProject === null) return;

    if (isEmpty(projectData.glassOptions)) setGlasses();
    else if (LAST_QUERY === copiedProject.lastQuery) handleProjectData();
  }, [copiedProject, projectData.glassOptions]);

  useEffect(() => {});

  return (
    <ProjectDataContext.Provider
      value={{
        projectData,
        dispatch,
      }}
    >
      {children}
    </ProjectDataContext.Provider>
  );
};

export default ProjectDataProvider;
