export const MIN_SYSTEM_HEIGHT = 1800;
export const MAX_SYSTEM_HEIGHT = 2700;
export const MAX_SYSTEM_WIDTH = 3200;
export const MIN_DOOR_WIDTH = 500;
export const MAX_DOOR_WIDTH = 1200;
export const MIN_DOOR_HEIGHT = 1900;
export const MAX_DOOR_HEIGHT = 2400;
export const MIN_GLASS_WIDTH = 200;
export const MAX_GLASS_WDITH = 2000;
export const MIN_UPPER_GLASS_HEIGHT = 100;
export const MIN_WALL_HEIGHT = 700;
export const MAX_WALL_HEIGHT = 2000;