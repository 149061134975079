/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-useless-return */
/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
/* eslint-disable func-names */
import './styles/app.css';
import './styles/lasitek.css';
import './styles/loading.css';
import './styles/parameters.css';
import './styles/header.css';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { isEmpty } from 'lodash';
import RestService from './services/RestService';
import Section from './views/Section';
import CieloModel from './views/CieloModel';
import Constants from './constants';
import Header from './views/Header';
import Requests from './Requests';
import FormProvider from './providers/FormProvider/provider';
import { productDefinitionQueryBody } from './utils';
import useProjectData from './providers/ProjectDataProvider/use';
import { getCost } from './api';
import sendDataToParent from './postMessage';

const requestQueue = [];

const App = () => {
  const { projectData, dispatch } = useProjectData();
  const { t } = useTranslation('common');
  const checkModelLoadingState = () => {
    if (Requests.counter === 0) {
      dispatch({ type: 'loading_model', payload: false });
      makeCostRequest(projectData.projectId);
    }
  };

  const setSection = (section) => {
    dispatch({ type: 'current_section', payload: section });
  };

  const setCurrentTemplate = (template) => {
    dispatch({ type: 'current_template', payload: template });
  };

  const checkCostLoadingState = () => {
    if (Requests.cost === 0 && Requests.counter === 0) {
      dispatch({ type: 'loading_cost', payload: false });
    }
  };

  const makeCostRequest = (projectId) => {
    Requests.cost += 1;
    getCost(projectId)
      .then((data) => {
        dispatch({
          type: 'project_cost',
          payload: parseFloat(data.priceWithVat),
        });
        Requests.cost -= 1;
        checkCostLoadingState();
      })
      .catch((error) => {
        console.error(error);
        Requests.cost -= 1;
        checkCostLoadingState();
      });
  };

  const checkRequestsQueue = () => {
    if (!isEmpty(requestQueue)) {
      const { data, path } = requestQueue.shift();
      makeUpdateRequestCall(data, path);
    }
  };

  const makeUpdateRequest = (data, apiPath, id = null) => {
    const path = id !== null ? `${apiPath}/${id}` : apiPath;
    if (Requests.counter === 0) {
      makeUpdateRequestCall(data, path, id);
    } else {
      requestQueue.push({
        data,
        path,
      });
    }
  };

  const makeUpdateRequestCall = (data, apiPath) => {
    Requests.counter += 1;
    dispatch({ type: 'loading_model', payload: true });
    dispatch({ type: 'loading_cost', payload: true });

    return RestService.request('PUT', apiPath, data)
      .then(() => {
        Requests.counter -= 1;
        checkModelLoadingState();
        checkRequestsQueue();
      })
      .catch((error) => {
        console.error(error);
        Requests.counter -= 1;
        checkModelLoadingState();
        checkRequestsQueue();
      });
  };

  const sendDataToParentWindow = () => {
    sendDataToParent({
      id: projectData.projectId,
      cost: projectData.projectCost,
    });
  };

  const sendDataToParentWindowReload = () => {
      sendDataToParent({
      id: projectData.projectId,
      cost: projectData.projectCost,
    });
    window.location.reload();
    
  }

  const allSidesQuery = (parameter, newValue) => {
    const query = [];
    query.push({
      id: projectData.door.id,
      [parameter]: newValue,
    });
    if (projectData.leftSide) {
      query.push({
        id: projectData.leftSide.id,
        [parameter]: newValue,
      });
    }
    if (projectData.rightSide) {
      query.push({
        id: projectData.rightSide.id,
        [parameter]: newValue,
      });
    }
    makeUpdateRequest(query, 'side/updateMultiple');
  };

  const sendRequests = async (data, errors) => {
    const sidesData = {};
    let systemHeight = 0;
    sidesData.door = { ...projectData.door };
    if (projectData.leftSide) {
      sidesData.left = { ...projectData.leftSide };
    }
    if (projectData.rightSide) {
      sidesData.right = { ...projectData.rightSide };
    }

    if (data.system_height && errors.system_height.length === 0) {
      systemHeight = parseInt(data.system_height);
      if (systemHeight !== projectData.systemHeight) {
        dispatch({ type: 'system_height', payload: systemHeight });
        allSidesQuery('height', systemHeight);
      }
    }

    const hasSystemWidthErrors =
      (errors.left_glass_width
        ? errors.left_glass_width.length !== 0
        : false) ||
      (errors.right_glass_width
        ? errors.right_glass_width.length !== 0
        : false) ||
      errors.door_width.length !== 0 ||
      errors.system_width.length !== 0;

    if (
      data.door_width &&
      errors.door_width.length === 0 &&
      !hasSystemWidthErrors
    ) {
      const doorWidth = parseInt(data.door_width);

      let newSidesData = {};

      const query = [];

      if (sidesData.left && sidesData.right) {
        newSidesData = {
          left: {
            ...sidesData.left,
            width: parseInt(data.left_glass_width),
          },
          right: {
            ...sidesData.right,
            width: parseInt(data.right_glass_width),
          },
        };
        dispatch({ type: 'left_side', payload: newSidesData.left });
        dispatch({ type: 'right_side', payload: newSidesData.right });
      } else if (sidesData.left) {
        newSidesData = {
          left: {
            ...sidesData.left,
            width: parseInt(data.left_glass_width),
          },
        };

        dispatch({ type: 'left_side', payload: newSidesData.left });
      } else {
        newSidesData = {
          right: {
            ...sidesData.right,
            width: parseInt(data.right_glass_width),
          },
        };

        dispatch({ type: 'right_side', payload: newSidesData.right });
      }

      dispatch({
        type: 'door',
        payload: { ...sidesData.door, width: doorWidth },
      });

      dispatch({ type: 'system_width', payload: data.system_width });

      if (doorWidth !== sidesData.door.width) {
        query.push({
          id: sidesData.door.id,
          width: doorWidth,
        });
      }

      if (
        sidesData.left &&
        parseInt(data.left_glass_width) !== sidesData.left.width
      )
        query.push({
          id: sidesData.left.id,
          width: parseInt(data.left_glass_width, 10),
        });
      if (
        sidesData.right &&
        parseInt(data.right_glass_width) !== sidesData.right.width
      )
        query.push({
          id: sidesData.right.id,
          width: parseInt(data.right_glass_width, 10),
        });

      if (query.length !== 0) makeUpdateRequest(query, 'side/updateMultiple');
    }

    if (data.door_height && errors.door_height.length === 0) {
      const doorHeight = parseInt(data.door_height, 10);

      if (doorHeight !== sidesData.door.beforeSpace) {
        const data = productDefinitionQueryBody(
          Constants.REFERENCE_BAR_TYPE,
          { beforeSpace: doorHeight },
          'spacing'
        );

        const newSideData = {
          ...sidesData.door,
          beforeSpace: doorHeight,
        };

        dispatch({ type: 'door', payload: newSideData });

        makeUpdateRequest(
          data,
          'productDefinitions',
          sidesData.door.referenceBarId
        );
      }
    }

    if (data.left_wall_height && errors.left_wall_height.length === 0) {
      const leftWallHeight = systemHeight - parseInt(data.left_wall_height, 10);

      if (leftWallHeight !== sidesData.left.beforeSpace) {
        const data = productDefinitionQueryBody(
          Constants.REFERENCE_BAR_TYPE,
          { beforeSpace: leftWallHeight },
          'spacing'
        );

        const newSideData = {
          ...sidesData.left,
          beforeSpace: leftWallHeight,
        };

        dispatch({ type: 'left_side', payload: newSideData });

        makeUpdateRequest(
          data,
          'productDefinitions',
          sidesData.left.referenceBarId
        );
      }
    }

    if (data.right_wall_height && errors.right_wall_height.length === 0) {
      const rightWallHeight = systemHeight - parseInt(data.right_wall_height, 10);

      if (rightWallHeight !== sidesData.right.beforeSpace) {
        const data = productDefinitionQueryBody(
          Constants.REFERENCE_BAR_TYPE,
          { beforeSpace: rightWallHeight },
          'spacing'
        );

        const newSideData = {
          ...sidesData.right,
          beforeSpace: rightWallHeight,
        };
        dispatch({ type: 'right_side', payload: newSideData });

        makeUpdateRequest(
          data,
          'productDefinitions',
          sidesData.right.referenceBarId
        );
      }
    }
  };

  useEffect(() => {
    // template selection shown in the first page
    document.getElementById('btn-menu').checked = true;
    if (projectData.projectId === null) {
      dispatch({ type: 'loading_model', payload: true });
      dispatch({ type: 'loading_cost', payload: true });
    } else {
      makeCostRequest(projectData.projectId);
    }
  }, [projectData.projectId]);
  return (
    <>
      <div className="app-container">
        <Header currentSection={projectData.currentSection} />
        <div className="horizontal-line" />
        <input type="checkbox" id="btn-menu" />

        <div id="main-content">
          {projectData.currentSection !== 1 && (
            <label className="btn-logo" htmlFor="btn-menu">
              <div
                id="show-hide-button"
                data-edit-text="Palaa valintoihin"
                data-see3d-text="Lasituksesi esikatselu"
              >
                <img
                  id="icon-3d"
                  src="/images/Cielo_yellow_3D_icon.png"
                  alt="cielo_icon"
                  height="45px"
                  width="45px"
                />
                <div id="wrap-arrow">
                  <div id="arrow" />
                </div>
              </div>
            </label>
          )}
          <section className="parameters">
            <div className="form-container">
              <FormProvider liveValidation onBlur={sendRequests}>
                <Section
                  currentSection={projectData.currentSection}
                  setSection={setSection}
                  makeUpdateRequest={makeUpdateRequest}
                  sendDataToParentWindow={sendDataToParentWindow}
                  setCurrentTemplate={setCurrentTemplate}
                />
              </FormProvider>
            </div>
          </section>
          <div id="model-container">
            <CieloModel
              sharedLinkCode={projectData.shareId}
              loading={projectData.isLoadingModel}
            />
          </div>
        </div>
        {projectData.currentSection === 1 && (
          <div
            onClick={() => {
              document.getElementById('btn-menu').checked = true;
              setSection(2);
            }}
            className="button color1 text-center next-button mobile-button"
            role="button"
            tabIndex={0}
          >
            <div>{t('next_button')}</div>
            <div className="button-triangle--next" />
          </div>
        )}
        {projectData.currentSection === 2 && (
          <div className="mobile-button floating-button">
            <div
              className="back-button"
              onClick={() => {
                document.getElementById('btn-menu').checked = true;
                setSection(1);
              }}
              role="button"
              tabIndex={0}
            >
              <div className="button-triangle--back" />
            </div>
            <div className="cost-container">
              <div className="total">{t('page_2.total')}</div>
              <div className="bar">|</div>
              {!projectData.isLoadingCost && (
                <div className="cost">{Math.ceil(projectData.projectCost)}</div>
              )}
              {projectData.isLoadingCost && (
                <ReactLoading
                  type="spinningBubbles"
                  color="#FFFFFF"
                  height="20px"
                  width="20px"
                />
              )}
              <div className="eur">EUR</div>
            </div>
            <div
              className="shopping-cart"
              role="button"
              onClick={sendDataToParentWindow}
              tabIndex={0}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="#ffed00"
                className="bi bi-cart"
                viewBox="0 0 16 16"
              >
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <div className="footer-cost">
          <div className="total-desktop">{`${t('page_2.total')}:`}</div>
          <div className="cost-desktop">
            {Math.ceil(projectData.projectCost)}
          </div>
        </div>
        <div
          className="add-to-cart"
          role="button"
          onClick={sendDataToParentWindowReload}
          tabIndex={0}
        />
      </div>
    </>
  );
};

export default App;
