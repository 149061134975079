/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../components/NumberInput';
import useProjectData from '../../providers/ProjectDataProvider/use';
import useForm from '../../providers/FormProvider/use';
import { MAX_SYSTEM_WIDTH } from '../../constants/validations';
import ProjectService from '../../services/ProjectService';

const SystemWidth = ({ initialValue }) => {
  const { t } = useTranslation('common');
  const { setFieldValue } = useForm();
  const { projectData } = useProjectData();
  const validate = useCallback((newValue) => {
    if (newValue > MAX_SYSTEM_WIDTH) {
      return [t('warnings.more_than', { number: MAX_SYSTEM_WIDTH })];
    }
    return [];
  }, []);

  const handleOnChange = (name, value) => {
    const currentValues = { door: projectData.door.width };
    if (projectData.leftSide !== undefined)
      currentValues.left = projectData.leftSide.width;
    if (projectData.rightSide !== undefined)
      currentValues.right = projectData.rightSide.width;

    const sidesWidth = ProjectService.getSidesWidth(
      currentValues,
      'system',
      value
    );

    for (const [type, value] of Object.entries(sidesWidth)) {
      setFieldValue(type, value);
    }
  };

  return (
    <NumberInput
      name="system_width"
      label={t('page_2.width')}
      validator={validate}
      initialValue={initialValue}
      onChange={handleOnChange}
    />
  );
};

export default SystemWidth;
